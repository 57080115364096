<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center">
          <ion-img
            style="height: 85px;width: 170px;"
            src="assets/icon/AAP_Logo_original_para-mail.png"
          ></ion-img>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-text>Gestión de campañas agrícolas</ion-text>
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-align-items-center"
          style="margin-top: 24px"
        >
          <ion-col class="ion-align-self-center" size-md="6" size="12">
            <ion-card>
              <ion-card-header>
                <ion-card-title size="4">Iniciar Sesión</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <!-- * Google sin uso de momento
                  <ion-button
                  expand="block"
                  text-color="white"
                  strong
                  color="googlebutton"
                  @click="auth('google')"
                >
                  GOOGLE
                </ion-button> -->
                <form @submit.prevent="authLocal">
                  <ion-item>
                    <ion-label position="floating">
                      Email
                    </ion-label>
                    <ion-input
                      :value="email"
                      inputmode="email"
                      type="email"
                      autocomplete="email"
                      placeholder="juanperez@hotmail.com"
                      required
                      @input="email = $event.target.value"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Contraseña</ion-label>
                    <ion-input
                      :value="password"
                      :type="showPass ? 'text' : 'password'"
                      placeholder="********"
                      required
                      @input="password = $event.target.value"
                    ></ion-input>
                    <ion-button
                      fill="clear"
                      slot="end"
                      size="large"
                      @click="showPass = !showPass"
                    >
                      <ion-icon
                        class="showPass ion-margin-bottom"
                        color="dark"
                        :icon="
                          showPass ? icons.eyeOffOutline : icons.eyeOutline
                        "
                      ></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item>
                    <a
                      slot="end"
                      style="cursor: pointer"
                      @click="$router.push('olvido-contrasena')"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </ion-item>
                  <ion-item v-show="error">
                    <ion-text color="danger">
                      <h4>{{ error }}</h4>
                    </ion-text>
                  </ion-item>
                  <ion-button
                    fill="outline"
                    class="login-btn"
                    color="medium"
                    @click="$router.push('/signup')"
                    v-if="false"
                  >
                    Crear cuenta
                  </ion-button>
                  <ion-button
                    slot="end"
                    style="margin-bottom: 16px"
                    class="ion-float-right login-btn"
                    type="submit"
                  >
                    Entrar
                  </ion-button>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Token from "@/api/Token";
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonPage,
  IonRow,
  IonImg,
  IonCard,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  alertController
} from "@ionic/vue";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

export default {
  components: {
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonPage,
    IonRow,
    IonImg,
    IonCard,
    IonCol,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon
  },
  props: ["tokenVerif", "emailVerif"],
  data: () => ({
    modalForgetPassword: false,
    reenvioMail: false,
    showPass: false,
    password: "",
    email: "",
    error: "",
    token: "",
    loading: false,
    icons: {
      eyeOutline,
      eyeOffOutline
    }
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
    user() {
      return this.$store.state.user;
    }
  },
  async created() {
    const auth = JSON.parse(sessionStorage.getItem("auth")) || {};
    sessionStorage.clear();
    Token.set(auth.token);
    const { data } = await this.getMe();
    if (data) this.redentToInicioPage();
  },
  async mounted() {
    if (this.$route.query.tkn) {
      await this.authLocal();
    }
    if (this.tokenVerif && this.emailVerif) {
      const { data } = await this.signupVerification({
        token: this.tokenVerif,
        email: this.emailVerif
      });
      if (data) {
        const resp = await this.getMe();
        if (resp.data) this.redentToInicioPage();
      } else {
        this.email = this.emailVerif;
        this.error = "Link para validar email expiro";
        const alert = await alertController.create({
          header: "Error de verificación",
          message: "Desea reenviar email de verificación?",
          buttons: [
            {
              text: "Reenviar",
              handler: this.reSendEmail
            },
            {
              text: "Cancelar",
              role: "cancel"
            }
          ]
        });
        return alert.present();
      }
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      "login",
      "loginAapresid",
      "getMe",
      "sendEmail",
      "signupVerification"
    ]),
    redentToInicioPage() {
      this.$router.push("/");
    },
    auth(type) {
      this.loading = true;
      const url = process.env.VUE_APP_SERVER_API + "/api/persona_auth/" + type;
      window.location.replace(url);
    },

    async authLocal() {
      this.error = "";
      const query = { email: this.email, password: this.password };
      const { error, data } =
        this.$route.query.tkn && !this.email && !this.password
          ? await this.loginAapresid(this.$route.query.tkn)
          : await this.login(query);
      if (error) {
        this.error = error;
        if (error === "Usuario no encontrado.") {
          this.$router.push({
            name: "signup",
            query: {
              tkn: this.$route.query.tkn
            }
          });
        }
      } else if (data.email_verified) {
        this.redentToInicioPage();
      } else {
        const alert = await alertController.create({
          header: "Validación de Cuenta",
          message:
            "Para ingresar debe validar su dirección de email desde el correo enviado. <br/><br/>Si no lo encuentra, verifique en Correo no Deseado.",
          buttons: [
            {
              text: "Reenviar el email de Validación",
              handler: () => {
                this.reSendEmail();
              }
            }
          ]
        });
        return alert.present();
      }
    },
    async reSendEmail() {
      this.loading = true;
      const { error } = await this.sendEmail({ email: this.email });
      if (error) {
        this.error = error;
      } else {
        this.reenvioMail = false;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.ion-margin-top {
  margin-top: 150px;
}
.showPass {
  position: relative;
  top: 50%;
  transform: translateY(-25%);
}

@media only screen and (min-width: 1024px) {
  #headerAAG {
    margin-left: 296px;
    margin-right: 296px;
    margin-top: 32px;
  }
  #tittle {
    margin-left: 104px;
  }
}

.login-btn {
  margin-top: 25px;
}
</style>
